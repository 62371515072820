import React from 'react';
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import NewsDetails from "../components/NewsDetails";
import NavThree from "../components/NavThree";
import {graphql} from "gatsby"
import "../assets/css/blog.css"

export const query = graphql`
    query($id: String!){
        allStrapiArticles(filter: {slug: {eq: $id}}) {
            nodes {
                id
                title
                subtitle
                slug
                shortdescription
                metadescription
                content
                video
                categories {
                    Name
                    slug
                }
                code {
                    element
                    elements
                }
                image {
                    localFile{
                        sharp: childImageSharp {
                            fluid(  maxHeight: 427, maxWidth: 770,quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            totalCount
        }
        latest: allStrapiArticles(limit: 4, sort: {fields: updated_at, order: DESC}) {
            edges {
                node {
                    id
                    slug
                    title
                    shortdescription
                    image {
                        localFile{
                            sharp: childImageSharp {
                                fluid( maxWidth: 66, maxHeight: 66, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
const BlogPage = ({data}) => {
    const blogContent = data.allStrapiArticles.nodes[0]
    const latestArticles = data.latest.edges.map(nodes=>nodes.node)
    console.log(blogContent)
    return (
        <Layout  title={"OshyTech - " + blogContent.title}
                 metaDescription={ blogContent.metaDescription }
                 image={blogContent.image.localFile.sharp.fluid.src}
                 keywords={blogContent.categories.map(cat=>cat.Name)}>
            <NavThree active={"blog"}/>
            <PageHeader title={blogContent.title}/>
            <NewsDetails blogData={blogContent} latestArticles={latestArticles}/>
            <Footer/>
        </Layout>
    );
};

export default BlogPage;
