import React from "react";
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {Link} from "gatsby";
import Img from "gatsby-image";


const NewsDetails = ({blogData, latestArticles}) => {
    const textSections = blogData.content.split("<<-->>")
    let codeMode = ""
    if (blogData.code === null || blogData.code === "") {
        codeMode = "no-code"
    } else if (blogData.code.element !== "") {
        codeMode = "single-code"
    } else {
        codeMode = "multi-code"
    }
    const getSection = (text, index) => {
        if (codeMode === "no-code") return normalContent(text)
        else if (codeMode === "single-code") {
            if (text.startsWith("code")) {
                return singleCodeContent(text.substring(4))
            } else {
                return normalContent(text)
            }
        }
    }
    const normalContent = (text) => {
        return <div dangerouslySetInnerHTML={{__html: text}}/>
    }
    const singleCodeContent = (text) => {
        return <SyntaxHighlighter
            language={blogData.code.element}
            style={dracula}
            wrapLines
            wrapLongLines
            showLineNumbers>
            {text.trim()}
        </SyntaxHighlighter>
    }
    //const codeList = blogData.code.elements
    return (
        <section className="blog-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-one__single">
                            <div className="block-title text-center">
                                <h2 className="block-title__title">
                                    {blogData.subtitle} <br/>
                                </h2>
                            </div>
                            {blogData.video === null ?
                                <div className="post-image blog-one__image">
                                    <Img fluid={{...blogData.image.localFile.sharp.fluid}} alt={blogData.title}/>
                                </div>
                                :
                                <div className="post-image blog-one__video">
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${blogData.video}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded youtube"
                                    />
                                </div>}
                            <div className="blog-one__content text-justify">
                                {textSections.map((text, index) => getSection(text, index))}
                            </div>
                            <div className="text-right">
                                Tags: {blogData.categories.map(category => <a rel={"nofollow"} id={category.slug}
                                                                              href={category.slug}>{category.Name}</a>)}
                            </div>
                        </div>
                        <div className="share-block">
                            <div className="left-block">
                                <p>
                                    Compartir este articulo:
                                </p>
                            </div>
                            <div className="social-block">
                                <a target="_blank" rel="noreferrer nofollow"
                                   href={"https://twitter.com/intent/tweet?text=https://oshy.tech/" + blogData.slug}>
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a target="_blank" rel="noreferrer nofollow"
                                   href={"https://www.facebook.com/dialog/share?app_id=1035471519867457&display=popup&href=https%3A%2F%2Foshy.tech%2F%2F" + blogData.slug}>
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a target="_blank" rel="noreferrer nofollow"
                                   href={"https://telegram.me/share/url?url=https://oshy.tech" + blogData.slug}>
                                    <i className="fab fa-telegram"></i>
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12">
                        <div className="sidebar">
                            <div className="sidebar__single sidebar__post">
                                <h3 className="sidebar__title">Últimos articulos</h3>

                                <div className="row">
                                    {latestArticles.map(latestArticle =>
                                        <div key={latestArticle.id} className="col-lg-6 pb-2">
                                            <div className="sidebar__post__single">
                                                <div className="sidebar__post-image">
                                                    <div className="inner-block">
                                                        <Img fluid={{
                                                            ...latestArticle.image.localFile.sharp.fluid,
                                                            aspectRatio: 11 / 9
                                                        }} alt={latestArticle.title}/>
                                                    </div>
                                                </div>
                                                <div className="sidebar__post-content">
                                                    <h4 className="sidebar__post-title">
                                                        <Link href={latestArticle.slug}>
                                                            {latestArticle.title}
                                                        </Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NewsDetails;
